import React, { useEffect } from "react";
import { ErrorBoundaryStyled } from "./styles";
import Loading from "components/Common/Loading";
import { FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import useLoadingStore from "stores/loading";
import useAuth from "hooks/useAuth";

export default function ErrorBoundary({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const navigate = useNavigate();
  const isTokenError = error.toString().toUpperCase().includes("JWT");
  const unRegistedUser = error.toString() === "일치하는 유저가 없습니다.";
  const unLogin = error.toString() === "로그인정보를 입력해주세요.";

  const { onHideLoading } = useLoadingStore((state) => state.action);
  const { clearAuthToken } = useAuth();

  const storageClear = async () => {
    localStorage.clear();
    clearAuthToken();
    window.alert("다시 로그인 해주세요");
    navigate("/", { replace: true });
    resetErrorBoundary();
    onHideLoading();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isTokenError || unRegistedUser || unLogin) {
        storageClear();
      }
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenError, unRegistedUser, unLogin]);

  return (
    <ErrorBoundaryStyled>
      <Loading />
    </ErrorBoundaryStyled>
  );
}
