import {
  DefaultBtn,
  GuideChatBox,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import LocationChat from "./search/LocationChat";
import KeywordChat from "./search/KeywordChat";
import { useBackPressModal } from "hooks/useBackPressModal";
import useJobSearchInfoStore from "stores/jobSearchInfo";

const Chat = () => {
  useBackPressModal();

  const navigate = useNavigate();
  const names = useJobSearchInfoStore((state) => state.names);

  const [isNextWithInput, setIsNextWithInput] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const isNotRecommended = useJobSearchInfoStore(
    (state) => state.isNotRecommended
  );

  const onClickHandler = () => {
    setIsNextWithInput(true);
  };

  useEffect(() => {
    if (isNextWithInput && names.length > 0) {
      navigate("result");
    }
  }, [isNextWithInput, names, navigate]);

  return (
    <>
      <Container>
        <ScrollContainer>
          {/* Default */}
          <GuideChatBox
            title={
              "환영합니다! 몇 가지 정보만 알려주시면 맞춤형 일자리 추천을 해드릴게요!"
            }
          />

          <GuideChatBox title={"근무를 원하는 지역을 입력해주세요."} />
          {/* Location */}
          <LocationChat />

          <KeywordChat
            isNextWithInput={isNextWithInput}
            setDisabled={setDisabled}
          />
        </ScrollContainer>
      </Container>
      {isNotRecommended === true && (
        <ButtonSection>
          <DefaultBtn disabled={disabled} onClick={onClickHandler}>
            {"입력하기"}
          </DefaultBtn>
        </ButtonSection>
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 3rem 1.6rem 20rem;
`;

const ButtonSection = styled.section`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default Chat;
