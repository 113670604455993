import { ChatSelectBox } from "@woojin0808/seniorz-react-ts-web-design-system";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useJobSearchInfoStore, {
  KeywordSelectList,
  KeywordSelectType,
} from "stores/jobSearchInfo";
import styled from "styled-components";

const DELAY_TIME = 1000;

const KeywordSelectChat = () => {
  const navigate = useNavigate();

  const setKeywordIdList = useJobSearchInfoStore(
    (state) => state.action.setKeywordIdList
  );

  const location = useJobSearchInfoStore(
    (state) => state.keywordIdList.location
  );
  const physical = useJobSearchInfoStore(
    (state) => state.keywordIdList.physical
  );
  const preferSit = useJobSearchInfoStore(
    (state) => state.keywordIdList.preferSit
  );
  const home = useJobSearchInfoStore((state) => state.keywordIdList.home);
  const computer = useJobSearchInfoStore(
    (state) => state.keywordIdList.computer
  );
  const computerDetail = useJobSearchInfoStore(
    (state) => state.keywordIdList.computerDetail
  );
  const write = useJobSearchInfoStore((state) => state.keywordIdList.write);
  const communication = useJobSearchInfoStore(
    (state) => state.keywordIdList.communication
  );
  // const time = useJobSearchInfoStore((state) => state.keywordIdList.time);
  // const simple = useJobSearchInfoStore((state) => state.keywordIdList.simple);
  // const rule = useJobSearchInfoStore((state) => state.keywordIdList.rule);

  const [step, setStep] = useState<number>(1);

  const locationBtns = [
    { text: "실내", value: [1] },
    { text: "실외", value: [2] },
    { text: "상관없음", value: [1, 2] },
  ];
  const physicalBtns = [
    { text: "네", value: [3] },
    { text: "아니오", value: [4] },
    { text: "상관없음", value: [3, 4] },
  ];
  const preferSitBtns = [
    { text: "네", value: [5] },
    { text: "아니오", value: [6] },
    { text: "상관없음", value: [5, 6] },
  ];
  const homeBtns = [
    { text: "네", value: [7] },
    { text: "아니오", value: [8] },
    { text: "상관없음", value: [7, 8] },
  ];
  const computerBtns = [
    { text: "네", value: "possible" },
    { text: "아니오", value: [11] },
    { text: "상관없음", value: [9, 10, 11] },
  ];
  const computerDetailBtns = [
    { text: "복잡한 기술 알아요", value: [9] },
    { text: "기본 사용법 알아요", value: [10] },
  ];
  const writeBtns = [
    { text: "네", value: [12] },
    { text: "아니오", value: [13] },
    { text: "상관없음", value: [12, 13] },
  ];
  const communicationBtns = [
    { text: "네", value: [14] },
    { text: "아니오", value: [15] },
    { text: "상관없음", value: [14, 15] },
  ];
  // const timeBtns = [
  //   { text: "네", value: [16] },
  //   { text: "아니오", value: [17] },
  //   { text: "상관없음", value: [16, 17] },
  // ];
  // const simpleBtns = [
  //   { text: "네", value: [18] },
  //   { text: "아니오", value: [19] },
  //   { text: "상관없음", value: [18, 19] },
  // ];
  // const ruleBtns = [
  //   { text: "네", value: [20] },
  //   { text: "아니오", value: [21] },
  //   { text: "상관없음", value: [20, 21] },
  // ];

  useEffect(() => {
    // if (!!rule) return setStep(11);
    // if (!!simple) return setStep(10);
    // if (!!time) return setStep(9);
    if (!!communication) return setStep(8);
    if (!!write) return setStep(7);
    if ((!!computer && computer.value !== "possible") || !!computerDetail)
      return setStep(6);
    if (!!home) return setStep(5);
    if (!!preferSit) return setStep(4);
    if (!!physical) return setStep(3);
    if (!!location) return setStep(2);
  }, [
    location,
    physical,
    preferSit,
    home,
    computer,
    computerDetail,
    write,
    communication,
    // time,
    // simple,
    // rule,
  ]);

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (step >= 8) {
      const timer = setTimeout(
        () => navigate("result", { replace: true }),
        1000
      );
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        if (listRef.current) {
          listRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, DELAY_TIME + 100);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onSelect = (key: keyof KeywordSelectList, value: KeywordSelectType) => {
    setKeywordIdList({ key: key, value: value });
  };

  return (
    <>
      <BoxContainer>
        <ChatSelectBox
          delayTime={DELAY_TIME}
          btnListWidth={23}
          selectValue={location}
          setSelectValue={(value: KeywordSelectType) =>
            onSelect("location", value)
          }
          title={"주로 어떤 장소에서 일하고 싶으신가요?"}
          btnList={locationBtns}
        />
      </BoxContainer>
      {step >= 2 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={physical}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("physical", value)
            }
            title={"몸을 많이 쓰는 일도 괜찮으신가요?"}
            btnList={physicalBtns}
          />
        </BoxContainer>
      )}
      {step >= 3 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={preferSit}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("preferSit", value)
            }
            title={"주로 앉아서 하는 일을 선호하시나요?"}
            btnList={preferSitBtns}
          />
        </BoxContainer>
      )}
      {step >= 4 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={home}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("home", value)
            }
            title={"집에서 일하는 걸 선호하시나요?"}
            btnList={homeBtns}
          />
        </BoxContainer>
      )}
      {step >= 5 && (
        <>
          <BoxContainer>
            <ChatSelectBox
              delayTime={DELAY_TIME}
              btnListWidth={23}
              selectValue={computer}
              setSelectValue={(value: KeywordSelectType) =>
                onSelect("computer", value)
              }
              title={"컴퓨터를 사용할 줄 아시나요?"}
              btnList={computerBtns}
            />
          </BoxContainer>
          {computer?.value === "possible" && (
            <BoxContainer>
              <ChatSelectBox
                delayTime={DELAY_TIME}
                btnListWidth={32.8}
                selectValue={computerDetail}
                setSelectValue={(value: KeywordSelectType) =>
                  onSelect("computerDetail", value)
                }
                title={"어느정도 수준으로 사용하시나요?"}
                content={
                  "복잡한 기술 알아요 : 엑셀, 파워포인트 등 사용 가능\n기본 사용법 알아요 : 타자 치기, 계산, 검색 등 가능"
                }
                btnList={computerDetailBtns}
              />
            </BoxContainer>
          )}
        </>
      )}
      {step >= 6 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={write}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("write", value)
            }
            title={"종이에 쓰거나 손으로 하는 일을 선호하시나요?"}
            btnList={writeBtns}
          />
        </BoxContainer>
      )}
      {step >= 7 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={communication}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("communication", value)
            }
            title={"다른 사람과 소통할 수 있는 일을 선호하시나요?"}
            btnList={communicationBtns}
          />
        </BoxContainer>
      )}
      {/* {step >= 8 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={time}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("time", value)
            }
            title={"일하는 시간을 자유롭게 조절하고 싶으신가요?"}
            btnList={timeBtns}
          />
        </BoxContainer>
      )}
      {step >= 9 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={simple}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("simple", value)
            }
            title={"단순하고 복잡하지 않은 일을 찾고 계신가요?"}
            btnList={simpleBtns}
          />
        </BoxContainer>
      )}
      {step >= 10 && (
        <BoxContainer>
          <ChatSelectBox
            delayTime={DELAY_TIME}
            btnListWidth={23}
            selectValue={rule}
            setSelectValue={(value: KeywordSelectType) =>
              onSelect("rule", value)
            }
            title={"정해진 규칙대로 하는 일을 선호하시나요?"}
            btnList={ruleBtns}
          />
        </BoxContainer>
      )} */}
      <div ref={listRef} />
    </>
  );
};

const BoxContainer = styled.div`
  position: relative;
`;

export default KeywordSelectChat;
