import { differenceInCalendarDays } from "date-fns";

export const isSameCalendarDay = (dateLeft: Date, dateRight: Date) => {
  const isSameYear = dateLeft.getFullYear() === dateRight.getFullYear();
  const isSameMonth = dateLeft.getMonth() === dateRight.getMonth();
  const isSameDay = dateLeft.getDate() === dateRight.getDate();
  return isSameYear && isSameMonth && isSameDay;
};

export const getDayListInMonth = (year: number, month: number) => {
  const startDate = new Date(year, month, 1, 15, 0, 0, 0);
  const endDate = new Date(year, month + 1, 0, 15, 0, 0, 0);
  const endDay = endDate.getDate();
  return new Array(endDay)
    .fill(null)
    .map(
      (_item, index) =>
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          index + 1,
          15,
          0,
          0,
          0
        )
    );
};

export const calculateDifferentDays = (targetDate: string | Date) => {
  const calculate = differenceInCalendarDays(targetDate, new Date());

  return calculate <= 0 ? 0 : calculate;
};
