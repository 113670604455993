import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  Filter,
  FloatingIconBtn,
  JobCard,
  OtherBtn,
  TextIconBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { getJobListCustom, jobKeys, patchJobAlarm } from "apis/job";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import ResultList from "./result/ResultList";
import useJobSearchInfoStore from "stores/jobSearchInfo";

import { ReactComponent as Search } from "assets/2.0/search.svg";
import { ReactComponent as Bell } from "assets/2.0/alert.svg";
import { ReactComponent as Loading } from "assets/2.0/loading.svg";
import useModalState from "stores/modal";
import { calculateDifferentDays } from "utils/date";
// import { calculateDifferentDays } from "utils/date";

const MyResult = () => {
  const navigate = useNavigate();

  const { setClearAll } = useJobSearchInfoStore((state) => state.action);

  const { openBottomSheetWithSet, openDefaultPopupWithSet, closeAll } =
    useModalState((state) => state.action);

  const [orderKey, setOrderKey] = useState<"deadline" | "recent">("deadline");

  const listRef = useRef<HTMLUListElement>(null);

  const locationList = localStorage.getItem("location") ?? "";
  const keywordIdList = localStorage.getItem("keyword") ?? "";
  const names = localStorage.getItem("names") ?? "";

  const {
    data: customData,
    fetchNextPage: customFetchNextPage,
    hasNextPage: customHasNextPage,
    isFetchingNextPage: customIsFetchingNextPage,
    refetch: customRefetch,
  } = useInfiniteQuery({
    queryKey: jobKeys.getJobListCustom(
      !!locationList ? JSON.parse(locationList) : "",
      !!names
        ? JSON.parse(names)
        : !!keywordIdList
        ? JSON.parse(keywordIdList)
        : ""
    ),
    queryFn: async ({ pageParam }) =>
      await getJobListCustom({ page: pageParam, orderKey }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.data.result.nextUrl
        ? Number(lastPage.data.result.nextUrl.split("page=")[1])
        : undefined;
    },
    select: (response) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = response.pages.reduce((acc: any[], val) => {
        const concatArr = val.data.result.jobInfoList
          ? acc.concat(val.data.result.jobInfoList)
          : acc;
        return concatArr;
      }, []);

      const totalCount = response.pages[0].data.result.totalCount;

      return { data, totalCount };
    },
  });

  const FILTER_LIST = [
    {
      id: 0,
      text: "마감 순",
      onClick: () => setOrderKey("deadline"),
    },
    {
      id: 1,
      text: "최신 순",
      onClick: () => setOrderKey("recent"),
    },
  ];

  const { mutateAsync } = useMutation({
    mutationFn: async () => await patchJobAlarm(true),
    onSuccess: async () => {
      await openDefaultPopupWithSet({
        title: "알림 신청 완료!",
        content: "AI가 새로운 일자리를 발견하면 바로 알려드릴게요.",
        btnText: "확인",
        onClick: closeAll,
      });
    },
  });

  const customObserverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !customObserverRef.current ||
      !customHasNextPage ||
      customIsFetchingNextPage
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        customFetchNextPage();
      }
    });

    observer.observe(customObserverRef.current);

    return () => observer.disconnect();
  }, [customHasNextPage, customIsFetchingNextPage, customFetchNextPage]);

  const onClickChange = () => {
    openBottomSheetWithSet({
      title: "새로운 정보로\n일자리를 검색하시겠어요?",
      firstContent: "지금까지 저장된 일자리 정보가 삭제돼요.",
      secondContent:
        "알림을 받고 있다면 변경된 정보로 다시 알림 받기를 신청해주세요.",
      SVGComponent: Search,
      btnText: "새로 일자리 검색하기",
      onClickConfirm: () => {
        setClearAll();
        navigate("/chat");
        closeAll();
      },
      onClose: closeAll,
    });
  };

  const onClickAlert = () => {
    openBottomSheetWithSet({
      title: "맞춤형 일자리 추천\n알림을 신청하시겠어요?",
      firstContent: "AI가 맞춤형 일자리를 찾을 때마다 카카오톡으로 알려드려요.",
      secondContent: "알림이 오지 않을 경우 차단 여부를 확인해주세요",
      SVGComponent: Bell,
      btnText: "알림 신청하기",
      onClickConfirm: async () => await mutateAsync(),
      onClose: closeAll,
    });
  };

  const onClickScroll = () => {
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onClickCard = (id: number) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    customRefetch();
  }, [customRefetch, orderKey]);

  return (
    <Container>
      <p>AI 일자리 찾기</p>
      <ButtonSection>
        <section>
          <OtherBtn types={"primary"} onClick={onClickChange} />
          <OtherBtn types={"secondary"} onClick={onClickAlert} />
        </section>
        <FilterSection>
          <p>
            {`총 `}
            <b>{`${customData?.totalCount}`}</b>
            {`개의 일자리를 찾았어요`}
          </p>
          <Filter btnList={FILTER_LIST} align="right" alignValue={0.1}>
            {orderKey === "deadline" ? "마감 순" : "최신 순"}
          </Filter>
        </FilterSection>
      </ButtonSection>
      <List ref={listRef}>
        {customData && customData.data.length > 0 ? (
          customData.data.map(
            ({ id, company, location, name, type, salary, deadline }) => {
              const calculateDate =
                deadline && deadline.length > 0
                  ? calculateDifferentDays(
                      new Date(deadline[0], deadline[1] - 1, deadline[2])
                    )
                  : 0;
              return (
                <li key={id}>
                  <JobCard
                    hasExpired={
                      deadline && deadline.length > 0 && !isNaN(calculateDate)
                    }
                    expiredDay={calculateDate}
                    onClick={() => onClickCard(id)}
                    title={name}
                    infoList={[
                      { id: 0, key: "기업", value: company ?? "-" },
                      { id: 1, key: "위치", value: location ?? "-" },
                      { id: 2, key: "고용형태", value: type ?? "-" },
                      { id: 3, key: "급여", value: salary ?? "-" },
                    ]}
                  />
                </li>
              );
            }
          )
        ) : (
          <EmptyView>
            <p>매칭된 일자리가 없습니다.</p>
            <span>다른 지역, 조건으로 다시 검색해보세요</span>
            <TextIconBtn SVGComponent={Loading} onClick={onClickChange}>
              {"다시 검색"}
            </TextIconBtn>
          </EmptyView>
        )}
        <div ref={customObserverRef} style={{ marginTop: "-2rem" }} />
        {!customHasNextPage && <ResultList onClickAlert={onClickAlert} />}
      </List>
      <FloatingIconBtn onClick={onClickScroll} bottom={3} right={0.9} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const ButtonSection = styled.div`
  padding: 2rem 1.6rem 1rem;

  section {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
  }
`;

const FilterSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    ${typography.body2};
    color: var(--color-gray-800);
    b {
      ${typography.heading3}
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  padding: 1.5rem 1.6rem 10rem;
  overflow-y: auto;
  background-color: var(--color-gray-50);

  li {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

const EmptyView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: 4.8rem 3.2rem;

  & > p {
    ${typography.heading3};
    color: var(--color-gray-800);
  }

  & > span {
    ${typography.body2};
    color: var(--color-gray-700);
    margin-bottom: 1.6rem;
  }
`;

export default MyResult;
