import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useModalState from "stores/modal";

export const useBackPressModal = () => {
  const navigate = useNavigate();

  const { openSecondaryPopupWithSet, closeAll } = useModalState(
    (state) => state.action
  );

  useEffect(() => {
    window.history.pushState({ modalOpen: true }, "");

    const handleBackPress = () => {
      openSecondaryPopupWithSet({
        types: "secondary",
        title: "일자리 찾기를 종료할까요?",
        content: "지금까지 입력한 정보가 초기화됩니다. 정말 종료할까요?",
        btnText: "확인",
        onClick: () => {
          navigate(-2);
          closeAll();
        },
        btnTextSub: "취소",
        onClickSub: closeAll,
      });
    };

    window.addEventListener("popstate", handleBackPress);

    return () => {
      window.removeEventListener("popstate", handleBackPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
};
